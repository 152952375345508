// helpers
import { log } from 'shared/helpers/newRelic';

// constants
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

// defs
import {
  BottomNavConfig,
  TransformedStore,
  TransformedDefaultNav,
  Tab,
  TabIcon,
} from 'shared/components/BottomNavV2/types';

const logUndefinedConfig = ({
  tabName,
  tab,
  icon,
  selectedIcon,
}: {
  tabName: string;
  tab?: Tab;
  icon?: TabIcon;
  selectedIcon?: TabIcon;
}) => {
  if (tab && icon && selectedIcon) {
    return;
  }

  log({
    errTitle: 'Wrong Tab or Icon config',
    tags: { [TAG_IDENTIFIER.MODULE]: 'BOTTOM_NAV' },
    extraInfo: { tabName },
  });
};

const transformTabs = ({
  params = {},
  tabMap,
  tabs,
  icons,
}: {
  params?: Record<string, string>;
  tabMap: string[];
  tabs: BottomNavConfig['tabs'];
  icons: BottomNavConfig['icons'];
}) => {
  return tabMap.map((tabName) => {
    const tab = tabs[tabName];
    const icon = icons[tab.icon];
    const selectedIcon = icons[tab.selectedIcon];

    // log errors if any
    logUndefinedConfig({ tabName, tab, icon, selectedIcon });

    return {
      ...tab,
      tabName,
      icon,
      selectedIcon,
      params: {
        // spread tabs params
        ...(tab.params || {}),
        // spread store params, overide tabs params if any
        ...params,
      },
    };
  });
};

/**
 * @description - Transform bottom nav config data for rendering UI
 */
export const transformBottomNavConfig = (
  config: BottomNavConfig
): { defaultBottomNav?: TransformedDefaultNav; storesBottomNav?: TransformedStore[] } => {
  let defaultBottomNav, storesBottomNav;
  const { preference, stores, tabs, icons } = config;

  if (config.preference) {
    defaultBottomNav = {
      path: preference.path,
      tabs: transformTabs({ tabs, icons, params: preference.params, tabMap: preference.tabs }),
    };
  } else {
    log({
      errTitle: 'Default Bottom Nav Config missing',
      tags: { [TAG_IDENTIFIER.MODULE]: 'BOTTOM_NAV' },
    });
  }

  if (config.stores) {
    storesBottomNav = Object.keys(stores).map((storeName) => {
      const store = stores[storeName];
      return {
        path: store.path,
        tabs: transformTabs({ tabs, icons, params: store.params, tabMap: store.tabs }),
        storeName,
      };
    });
  }

  return { storesBottomNav, defaultBottomNav };
};
