// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// components
import Image from 'shared/components/ImageV2';

// defs
interface TabImageIconProps {
  url: string;
  iconStyle?: React.CSSProperties;
}

// styles
const ImageCnt = styled.div`
  width: 24px;
  height: 24px;
`;

function TabImageIcon({ url, iconStyle = {} }: TabImageIconProps) {
  return (
    <ImageCnt>
      <Image height={24} width={24} alt="" src={url} style={iconStyle} lazy={false} />
    </ImageCnt>
  );
}

export default memo(TabImageIcon);
