/* eslint-disable react-hooks/exhaustive-deps */
// libs
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@nykaa/ui-components';
import isEmpty from 'lodash/isEmpty';

// animation
import { fadeInAnimation } from 'shared/styles/animations/fadeIn';
import { css } from '@emotion/core';

// defs
import { ImageProps } from './types';

// constant
import { COLORS } from 'shared/styles/base';
import { LOADING_IMAGE_PLACEHOLDER } from 'shared/constants';

// styles
const Image = styled.img<{ bckgrndClr: string; bgImage: string; animate: boolean }>`
  width: 100%;
  height: auto;
  background-color: ${(props) => props.bckgrndClr};
  ${({ bgImage }) => bgImage && `background-image: url(${bgImage})`};
  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeInAnimation} 0.3s ease-in forwards;
    `}
`;

// helpers
import { getOptimisedImageUrl } from 'shared/helpers/utils';
import { getImageLoadingProps, getSrcSet } from 'shared/helpers/utils/images';

function ImageV2({
  src,
  width,
  height,
  aspectRatio,
  className = '',
  placeholderColor = COLORS.PLACEHOLDER,
  onLoadPlaceholderColor,
  alt = '',
  lazy = true,
  srcSet = '',
  onImageLoad,
  shouldAnimateImageLoad = false,
  shouldShowImageLoadPlaceHolder = false,
  ...props
}: ImageProps) {
  const [backgroundColor, setBackgroundColor] = useState(placeholderColor);
  const [imageLoaded, setImageLoaded] = useState(false);

  const imageRef = useRef<HTMLImageElement | null>(null);

  // if onLoad fires before hydration
  useEffect(() => {
    if (imageRef.current?.complete) {
      setBackgroundColor(onLoadPlaceholderColor);
    }
  }, [imageRef.current]);

  const handleImageLoad = () => {
    onImageLoad && onImageLoad();
    setImageLoaded(true);
    if (isEmpty(onLoadPlaceholderColor)) return;

    setBackgroundColor(onLoadPlaceholderColor);
  };

  let finalWidth = width;
  let finalHeight = height;

  if (aspectRatio && aspectRatio !== 0 && width) {
    finalWidth = width;
    finalHeight = width / aspectRatio;
  }

  if (aspectRatio && aspectRatio !== 0 && height) {
    finalHeight = height;
    finalWidth = height * aspectRatio;
  }

  if (width && height) {
    finalWidth = width;
    finalHeight = height;
  }

  const finalSrcSet = useMemo(() => {
    if (isEmpty(srcSet) && finalWidth !== undefined) {
      return getSrcSet(src, finalWidth);
    }
    return '';
  }, [finalWidth, src, srcSet]);
  return (
    <Image
      ref={imageRef}
      src={getOptimisedImageUrl(src, { width })}
      className={className}
      width={finalWidth}
      height={finalHeight}
      alt={alt}
      srcSet={finalSrcSet}
      //@ts-ignore
      loading={lazy ? 'lazy' : 'eager'}
      bckgrndClr={backgroundColor}
      onLoad={handleImageLoad}
      bgImage={shouldShowImageLoadPlaceHolder ? (imageLoaded ? '' : LOADING_IMAGE_PLACEHOLDER) : ''}
      animate={shouldAnimateImageLoad && imageLoaded}
      {...getImageLoadingProps(lazy)}
      {...props}
    />
  );
}

export default memo(ImageV2);
