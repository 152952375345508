// libs
import React, { memo } from 'react';

// components
import DefaultBottomNav from 'shared/components/BottomNavV2/components/DefaultBottomNav';
import StoreBottomNav from 'shared/components/BottomNavV2/components/StoreBottomNav';

// hooks
import { useUserData } from 'shared/components/UserData/context';

import { STORE_DATA_KEY } from 'shared/components/BottomNavV2/constants';

// defs
import { TransformedDefaultNav, TransformedStore } from 'shared/components/BottomNavV2/types';

interface ContextifiedBottomNavProps {
  defaultBottomNav?: TransformedDefaultNav;
  storesNav: TransformedStore[];
}

function ContextifiedBottomNav({ defaultBottomNav, storesNav }: ContextifiedBottomNavProps) {
  const { getUserData } = useUserData();

  // get saved store name from user data
  const storeName = getUserData(STORE_DATA_KEY);

  const storeNav = storesNav.find((store) => store.storeName === storeName);

  // render default bottom nav if no store nav found
  if (!storeNav) {
    // if we dont have default bottom nav config render null
    if (!defaultBottomNav) {
      return null;
    }

    return <DefaultBottomNav tabs={defaultBottomNav.tabs} />;
  }

  // render store bottom nav
  return <StoreBottomNav tabs={storeNav.tabs} storeName={storeNav.storeName} />;
}

export default memo(ContextifiedBottomNav);
