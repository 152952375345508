// libs
import React, { memo, useEffect, useState } from 'react';
import { styled } from '@nykaa/ui-components';
import { css } from '@emotion/core';

// components
import Tab from 'shared/components/BottomNavV2/components/Tab';
import StickyLoginBottomNav from 'shared/components/StickyLoginBottomNav';

// context
import { useAppLayoutContext } from 'shared/layouts/AppLayout/AppLayoutContext';

// defs
import { TransformedDefaultNav } from 'shared/components/BottomNavV2/types';

// constants
import { PAGENAME } from 'shared/components/StickyLoginBottomNav/constants';

// selector
import { isLoggedIn, isUserFetching } from 'shared/store/app/selectors';

// hook
import { useSelector } from 'react-redux';

interface BottomNavProps extends Omit<TransformedDefaultNav, 'path'> {
  storeName?: string;
}

// styles
import { fixedBottomCss } from 'shared/styles/mixin/core';

const Container = styled.div`
  ${fixedBottomCss(55)};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.textOutline};
  background-color: #fff;
  z-index: 4;
`;

const containerCss = css`
  margin-bottom: 56px;
`;

function BottomNav({ tabs, storeName = 'DEFAULT' }: BottomNavProps) {
  const { setCSS } = useAppLayoutContext();
  const [shouldShowStickLogin, setShouldShowStickLogin] = useState(false);
  const isUserLoggedIn = useSelector(isLoggedIn);
  const isUserDataFetching = useSelector(isUserFetching);

  useEffect(() => {
    setCSS(containerCss);
    if (!isUserDataFetching && !isUserLoggedIn) setShouldShowStickLogin(true);
    return () => {
      setCSS(undefined);
    };
  }, [setCSS, isUserDataFetching, isUserLoggedIn]);

  return (
    <>
      {shouldShowStickLogin && <StickyLoginBottomNav interactionLocation={PAGENAME.HOME} />}
      <Container>
        {tabs.map((tab) => (
          <Tab storeName={storeName} {...tab} key={tab.tabName} />
        ))}
      </Container>
    </>
  );
}

export default memo(BottomNav);
