// libs
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

// components
import DefaultBottomNav from 'shared/components/BottomNavV2/components/DefaultBottomNav';
import StoreBottomNav from 'shared/components/BottomNavV2/components/StoreBottomNav';
import ContextifiedBottomNav from 'shared/components/BottomNavV2/components/ContextifiedBottomNav';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// helpers
import { transformBottomNavConfig } from './helpers';

// defs
import { BottomNavRemoteConfig } from 'shared/components/BottomNavV2/types';

function BottomNavRenderer() {
  const { config } = useRemoteConfig('bottomNavV2') as BottomNavRemoteConfig;
  const [showBottomNav, setShowBottomNav] = useState(false);

  // render bottom nav only on client side as store name is
  // fetched from user data localstorage
  useEffect(() => {
    setShowBottomNav(true);
  }, []);

  const { defaultBottomNav, storesBottomNav = [] } = useMemo(
    () => transformBottomNavConfig(config),
    [config]
  );
  const bottomNavPaths = config.bottomNavPaths || [];

  if (showBottomNav === false) {
    return null;
  }

  return (
    <Switch>
      {defaultBottomNav && (
        <Route path={defaultBottomNav.path} exact>
          <DefaultBottomNav tabs={defaultBottomNav.tabs} />
        </Route>
      )}
      {storesBottomNav.map((store) => (
        <Route path={store.path} exact key={store.storeName}>
          <StoreBottomNav tabs={store.tabs} storeName={store.storeName} />
        </Route>
      ))}
      {bottomNavPaths.map((bottomNavPath) => (
        <Route path={bottomNavPath} key={bottomNavPath}>
          <ContextifiedBottomNav defaultBottomNav={defaultBottomNav} storesNav={storesBottomNav} />
        </Route>
      ))}
    </Switch>
  );
}

export default memo(BottomNavRenderer);
