// libs
import React, { memo } from 'react';

// components
import BottomNav from 'shared/components/BottomNavV2/components/BottomNav';

// defs
import { TransformedDefaultNav } from 'shared/components/BottomNavV2/types';

function DefaultBottomNav({ tabs }: Omit<TransformedDefaultNav, 'path'>) {
  return <BottomNav tabs={tabs} storeName="DEFAULT" />;
}

export default memo(DefaultBottomNav);
