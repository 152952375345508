import { getOptimisedImageUrl, imageHostRegExp } from 'shared/helpers/utils';

//will be moving all the image related helpers here
export const getSrcSet = (src: string, width: number) => {
  // check if image optimisations are supported
  if (imageHostRegExp.test(src) === false) {
    return '';
  }

  const optimisedSrc1x = getOptimisedImageUrl(src, { width });
  const optimisedSrc2x = getOptimisedImageUrl(src, { width: width * 2 });
  const optimisedSrc3x = getOptimisedImageUrl(src, { width: width * 3 });

  return `${optimisedSrc1x} 1x, ${optimisedSrc2x} 2x, ${optimisedSrc3x} 3x`;
};

export const getImageLoadingProps = (isLazy = false) => {
  const lazyLoadingProps = {
    fetchPriority: 'low',
    decoding: 'async',
    loading: 'lazy',
  };

  const eagerLoadingProps = {
    fetchPriority: 'high',
    decoding: 'async',
    loading: 'eager',
  };

  if (isLazy) return lazyLoadingProps;
  return eagerLoadingProps;
};
