// libs
import React, { memo, useEffect } from 'react';

// components
import BottomNav from 'shared/components/BottomNavV2/components/BottomNav';

// hooks
import { useUserData } from 'shared/components/UserData/context';

// constants
import { STORE_DATA_KEY } from 'shared/components/BottomNavV2/constants';

// defs
import { TransformedStore } from 'shared/components/BottomNavV2/types';

function StoreBottomNav({ tabs, storeName }: Omit<TransformedStore, 'path'>) {
  const { setUserData } = useUserData();

  useEffect(() => {
    // replace user journey store to store name
    setUserData(STORE_DATA_KEY, storeName);
  }, [setUserData, storeName]);

  return <BottomNav tabs={tabs} storeName={storeName} />;
}

export default memo(StoreBottomNav);
