// libs
import React, { memo, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

// components
import TabUI from 'shared/components/BottomNavV2/ui-components/Tab';

// analytics
import { onTabClick } from './analytics';

// defs
import { TabIcon } from 'shared/components/BottomNavV2/types';

interface TabProps {
  storeName: string;
  tabName: string;
  icon: TabIcon;
  selectedIcon: TabIcon;
  title: string;
  path: string;
  params?: Record<string, string>;
}

function Tab({ storeName, tabName, icon, selectedIcon, title, path, params = {} }: TabProps) {
  // check if tab path is same as current browser path
  const match = useRouteMatch(path);
  const isSelected = Boolean(match?.isExact);
  // icon to render based on whether tab is selected or not
  const tabIcon = isSelected ? selectedIcon : icon;

  const urlSearch = new URLSearchParams();

  // appending params to url
  Object.keys(params).forEach((key) => urlSearch.append(key, params[key]));

  const url = `${path}?${urlSearch.toString()}`;

  const onClick = useCallback(() => {
    // analytics for clicked tab
    onTabClick({ storeName, tabName, title });
    // re route to tab url
    window.location.href = url;
  }, [storeName, tabName, title, url]);

  return <TabUI title={title} isSelected={isSelected} icon={tabIcon} url={url} onClick={onClick} />;
}

export default memo(Tab);
