// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// global components
import RippleIt from 'shared/components/RippleIt';

// components
import TabIconUI from 'shared/components/BottomNavV2/ui-components/TabIcon';
import TabImageIcon from 'shared/components/BottomNavV2/ui-components/TabImageIcon';

// defs
import { TabIcon } from 'shared/components/BottomNavV2/types';

interface TabProps {
  icon: TabIcon;
  title: string;
  url: string;
  onClick: () => void;
  isSelected: boolean;
}

// styles
const TabContainer = styled(RippleIt)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  background-color: #fff;
  svg > path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

const Text = styled.span<{ isSelected: boolean }>`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.spacing10};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.secondary : theme.colors.textSecondary};
  ${({ theme }) => theme.typography.type120};
  letter-spacing: 0.1px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing10}`};
`;

function Tab({ icon, title, url, onClick, isSelected }: TabProps) {
  const iconUI =
    icon.type === 'image' ? (
      <TabImageIcon url={icon.url} iconStyle={icon.iconStyle} />
    ) : (
      <TabIconUI name={icon.name} isSelected={isSelected} />
    );

  return (
    <TabContainer href={url} as="a" onClick={onClick} data-at={title} preventDefault>
      {iconUI}
      <Text isSelected={isSelected}>{title}</Text>
    </TabContainer>
  );
}

export default memo(Tab);
