// libs
import React, { memo } from 'react';

// constants
import { COLORS } from 'shared/styles/base';

// components
import NykaaNLogo from '@nykaa/ui-components/Icons/nykaa-n-logo';
import Categories from '@nykaa/ui-components/Icons/shop';
import CategoriesFilled from '@nykaa/ui-components/Icons/shop-filled';
import Sale from '@nykaa/ui-components/Icons/offers';
import SaleFilled from '@nykaa/ui-components/Icons/offer-filled';
import Account from '@nykaa/ui-components/Icons/account-more';
import AccountFilled from '@nykaa/ui-components/Icons/user-filled';

// defs
interface TabIconProps {
  name:
    | 'LOGO'
    | 'CATEGORIES'
    | 'CATEGORIES_FILLED'
    | 'SALE'
    | 'SALE_FILLED'
    | 'ACCOUNT'
    | 'ACCOUNT_FILLED';
  isSelected: boolean;
}

const ICON_MAP = {
  LOGO: NykaaNLogo,
  CATEGORIES: Categories,
  CATEGORIES_FILLED: CategoriesFilled,
  SALE: Sale,
  SALE_FILLED: SaleFilled,
  ACCOUNT: Account,
  ACCOUNT_FILLED: AccountFilled,
};

const ICON_SIZE = '27px';

function TabIcon({ name, isSelected }: TabIconProps) {
  const Icon = ICON_MAP[name] || Sale;
  const selectedProps = isSelected ? { fillOpacity: 'none', color: COLORS.BRAND_PRIMARY_100 } : {},
    sizeProp = name === 'SALE' ? { size: ICON_SIZE } : {};

  return <Icon {...selectedProps} {...sizeProp} />;
}

export default memo(TabIcon);
