// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';

export const onTabClick = ({ title }: { title: string }) => {
  NFA.updateDatalayerBeforeNext({ bottomNavClicked: title }, TRACK_TYPE.PAGELOAD);
  NFA.track({
    event: 'bottom_nav_clicked',
    data: { bottomNavOption: title },
  });
};
