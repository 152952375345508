export const LOGIN_BOTTOM_NAV_TEXT = {
  LOOKING_FOR_DISCOUNT: 'Looking for Discounts?',
  LOGIN_AND_UNLOCK: 'login & unlock',
  LOGIN_CTA: 'Login Now',
};

export const LOGIN_NUDGE_EVENTS = {
  LOGIN_SIGNUP_START: 'login_signup_start',
  LOGIN_NUDGE_LOAD: 'login_nudge_load',
  LOGIN_NUDGE_CLOSED: 'login_nudge_closed',
};

export const OFFER_ICON_SIZE = '20px';

export const LOGIN_NUDGE_LOCATION = {
  PLP_LOGIN_NUDGE: 'loginNudge',
  HOME_PAGE_LOGIN: 'loginNudge',
};

export const PAGENAME = {
  PLP: 'plp',
  HOME: 'home',
};

export const DEFAULT_TRIGGER_TIME = 24;

export const VARIANT_INITIAL = 'Variant1';
